import React from 'react';

type Props = {
  nextHandler: (section: string) => void;
};

export const TribesSection: React.FC<Props> = ({ nextHandler }) => {
  return (
    <form>
      <div className="form-group">
        <label htmlFor="exampleInputEmail1" className="form-label">
          Tribes <small>(leave empty for all)</small>
        </label>
        <textarea className="form-control" id="mandatorySkills" autoComplete="off" rows={5} />
      </div>

      <div className="form-group">
        <label htmlFor="exampleInputEmail1" className="form-label">
          Repeat annotation
        </label>

        <div className="row align-items-center">
          <div className="col-auto">
            <input type="text" className="form-control" id="payment" autoComplete="off" />
          </div>
          <div className="col-auto ps-0">
            <span id="passwordHelpInline">times</span>
          </div>
        </div>
      </div>

      <a className="btn btn-primary" href="#!" onClick={() => nextHandler('payments')}>
        Next
      </a>
    </form>
  );
};
