import React from 'react';

type Props = {
  section: string;
  sectionHandler: (section: string) => void;
};

export const Sections: React.FC<Props> = ({ section, sectionHandler }) => {
  return (
    <div className="list-group">
      <a
        href="#!"
        className={`list-group-item list-group-item-action ${section === 'name' && 'active'}`}
        onClick={() => sectionHandler('name')}
      >
        Name
      </a>
      <a
        href="#!"
        className={`list-group-item list-group-item-action ${section === 'skills' && 'active'}`}
        onClick={() => sectionHandler('skills')}
      >
        Skills
      </a>
      <a
        href="#!"
        className={`list-group-item list-group-item-action ${section === 'tribes' && 'active'}`}
        onClick={() => sectionHandler('tribes')}
      >
        Tribes
      </a>
      <a
        href="#!"
        className={`list-group-item list-group-item-action ${section === 'payments' && 'active'}`}
        onClick={() => sectionHandler('payments')}
      >
        Payment
      </a>
      <a
        href="#!"
        className={`list-group-item list-group-item-action ${section === 'motivation' && 'active'}`}
        onClick={() => sectionHandler('motivation')}
      >
        Motivation
      </a>
      <a
        href="#!"
        className={`list-group-item list-group-item-action ${section === 'innolytiq' && 'active'}`}
        onClick={() => sectionHandler('innolytiq')}
      >
        Innolytiq
      </a>
    </div>
  );
};
