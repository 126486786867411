import React from 'react';
import { Link } from 'react-router-dom';

type NavItem = {
  link: string;
  label: string;
  section: string;
};

type Props = {
  items: NavItem[];
  activeSection: string;
};

export const TabsNavigation: React.FC<Props> = ({ items, activeSection }) => {
  return (
    <ul className="nav nav-tabs mx-4">
      {items.map(({ link, label, section }) => {
        return (
          <li className="nav-item">
            <Link className={`nav-link ${section === activeSection && 'active'}`} aria-current="page" to={link}>
              {label}
            </Link>
          </li>
        );
      })}
    </ul>
  );
};
