import { BrowserRouter as Router, Routes, Route, useParams } from 'react-router-dom';
import { NewTask } from './components/tasks/NewTask';
import { Tasks } from './components/tasks/Tasks';
import { AnnotationTask } from './components/tasks/AnnotationTask';

import { People } from './components/tribes/people/People';
import { Tribes } from './components/tribes/tribes/Tribes';
import { MainContainer } from './containers/MainContainer';
import { Statistics } from './components/tasks/Statistics';
import { Queue } from './components/tasks/Queue';

export default function App() {
  const params = useParams();
  return (
    <Router>
      <div>
        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Routes>
          <Route
            path="/"
            element={
              <MainContainer section="tribes">
                <People />
              </MainContainer>
            }
          />
          <Route
            path="/tribes"
            element={
              <MainContainer section="tribes">
                <Tribes />
              </MainContainer>
            }
          />
          <Route
            path="/tasks"
            element={
              <MainContainer section="tasks">
                <Tasks />
              </MainContainer>
            }
          />
          <Route
            path="/tasks/add"
            element={
              <MainContainer section="tasks">
                <NewTask section={params.section || 'name'} />
              </MainContainer>
            }
          />
          <Route
            path="/tasks/annotation/:id"
            element={
              <MainContainer section="tasks">
                <AnnotationTask />
              </MainContainer>
            }
          />
          <Route
            path="/tasks/statistics/:id"
            element={
              <MainContainer section="tasks">
                <Statistics />
              </MainContainer>
            }
          />
          <Route
            path="/tasks/queue/:id"
            element={
              <MainContainer section="tasks">
                <Queue />
              </MainContainer>
            }
          />
        </Routes>
      </div>
    </Router>
  );
}

// function Home() {
//   return <h2>Home</h2>;
// }

// function About() {
//   return <h2>About</h2>;
// }

// function Users() {
//   return <h2>Users</h2>;
// }

// import { Navbar } from './components/header/Navbar';
// import { People } from './components/tribes/people/People';

// function App() {
//   return (
//     <div className="">
//       <Navbar />

//       <div className="mx-4">
//         <ul className="nav nav-tabs">
//           <li className="nav-item">
//             <a className="nav-link active" aria-current="page" href="#">
//               People
//             </a>
//           </li>
//           <li className="nav-item">
//             <a className="nav-link" href="#">
//               Tribes
//             </a>
//           </li>
//         </ul>
//       </div>

//       <People />
//     </div>
//   );
// }

// export default App;
