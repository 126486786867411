import React from 'react';

type Props = {
  unit?: string;
  value: number;
};

export const Numbers: React.FC<Props> = ({ value, unit = '' }) => {
  return (
    <div>
      <div className="row g-0 align-items-center justify-content-center">
        <div className="col-auto">
          <div className="h2 mb-0"></div>
        </div>
        <div className="col-auto">
          <div className="display-2 mb-0">{value}</div>
        </div>
      </div>
      <div className="h6 text-uppercase text-center text-muted mb-5">{unit}</div>
    </div>
  );
};
