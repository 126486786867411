import { useState } from 'react';
import { Chart } from './Chart';
import { Numbers } from './Numbers';

export const NumberOfUnassignedDocuments = () => {
  const [viewType, setViewType] = useState<string>('chart');
  const data = {
    labels: ['Jan-2020', 'Apr-2020', 'Jul-2020', 'Oct-2020', 'Jan-2021', 'Apr-2021', 'Jul-2021', 'Oct-2021'],
    datasets: [
      {
        label: 'number of unassigned documents',
        data: [5, 4, 3, 7, 10, 6, 3, 3],
        fill: false,
        backgroundColor: '#2c7be5',
        borderColor: '#2c7be5',
      },
    ],
  };

  return (
    <div className="card" style={{ height: '100%' }}>
      <div className="card-header">
        <h4 className="card-header-title">Number of unassigned documents</h4>

        <ul className="nav nav-tabs nav-tabs-sm card-header-tabs">
          <li className="nav-item">
            <a href="#!" className={`nav-link ${viewType === 'chart' && 'active'}`} data-bs-toggle="tab">
              <i className="fe fe-bar-chart-2" onClick={() => setViewType('chart')}></i>
            </a>
          </li>
          <li className="nav-item">
            <a href="#!" className={`nav-link ${viewType === 'number' && 'active'}`} data-bs-toggle="tab">
              <i className="fe fe-hash" onClick={() => setViewType('number')}></i>
            </a>
          </li>
        </ul>
      </div>
      <div className="card-body d-flex align-items-center justify-content-center">
        {viewType === 'chart' ? <Chart data={data} /> : <Numbers value={3} />}
      </div>
    </div>
  );
};
