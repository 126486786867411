import React from 'react';
import { AddButton } from '../shared/AddButton';
import { TaskRow, Task } from './TaskRow';

export const Tasks: React.FC = () => {
  const tasks: Task[] = [
    {
      name: 'Table',
      type: 'Annotation',
      unassigned: 0,
      inProgress: 10,
    },
    {
      name: 'Spreading',
      type: 'Annotation',
      unassigned: 10,
      inProgress: 12,
    },
    {
      name: 'Spreading',
      type: 'Verification',
      unassigned: 8,
      inProgress: 0,
    },
  ];
  return (
    <>
      <div
        className="card m-4"
        data-list='{"valueNames": ["item-name", "item-title", "item-email", "item-phone", "item-score", "item-company"], "page": 10, "pagination": {"paginationClass": "list-pagination"}}'
        id="contactsList"
      >
        <div className="card-header">
          <div className="row align-items-center">
            <div className="col">
              {/* <!-- Form --> */}
              <form>
                <div className="input-group input-group-flush input-group-merge input-group-reverse">
                  <input className="form-control list-search" type="search" placeholder="Search" />
                  <span className="input-group-text">
                    <i className="fe fe-search"></i>
                  </span>
                </div>
              </form>
            </div>
          </div>
          {/*  <!-- / .row --> */}
        </div>
        <div className="table-responsive">
          <table className="table table-sm table-hover table-nowrap card-table">
            <thead>
              <tr>
                <th>
                  <a className="list-sort text-muted" data-sort="item-name" href="!#">
                    NAME
                  </a>
                </th>
                <th>
                  <a className="list-sort text-muted" data-sort="item-name" href="!#">
                    TYPE
                  </a>
                </th>
                <th>
                  <a className="list-sort text-muted" data-sort="item-name" href="!#">
                    UNASSIGNED
                  </a>
                </th>
                <th>
                  <a className="list-sort text-muted" data-sort="item-name" href="!#">
                    IN PROGRESS
                  </a>
                </th>
                <th>
                  <a className="list-sort text-muted" data-sort="item-company" href="!#">
                    ACTIONS
                  </a>
                </th>
              </tr>
            </thead>
            <tbody className="list fs-base">
              {tasks.map((task) => (
                <TaskRow task={task} />
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <div className="d-flex justify-content-center">
        <nav aria-label="...">
          <ul className="pagination">
            <li className="page-item disabled">
              <span className="page-link">Previous</span>
            </li>
            <li className="page-item disabled">
              <a className="page-link" href="!#">
                Next
              </a>
            </li>
          </ul>
        </nav>
      </div>

      <AddButton link="/tasks/add?section=name" />
    </>
  );
};
