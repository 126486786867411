import React from 'react';

type Props = {
  nextHandler: (section: string) => void;
};

export const InnolytiqSection: React.FC<Props> = () => {
  return (
    <form>
      <div className="form-group">
        <label htmlFor="type" className="form-label">
          Innolytiq view
        </label>
        <select className="form-control">
          <option></option>
          <option>Balance sheet</option>
          <option>Income statement</option>
        </select>
      </div>

      <a className="btn btn-primary" href="#!" onClick={() => {}}>
        Save
      </a>
    </form>
  );
};
