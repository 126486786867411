import { Numbers } from './Numbers';

export const Total = () => {
  return (
    <div className="card" style={{ height: '100%' }}>
      <div className="card-header">
        <h4 className="card-header-title">Total spent</h4>
      </div>
      <div className="card-body d-flex align-items-center justify-content-center">
        <Numbers value={120} unit="token" />
      </div>
    </div>
  );
};
