import React from 'react';
import { Navbar } from '../components/header/Navbar';

type Props = {
  section: string;
};

export const MainContainer: React.FC<Props> = ({ children, section }) => {
  return (
    <div>
      <Navbar section={section} />
      {children}
    </div>
  );
};
