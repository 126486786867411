import React from 'react';
import { TabsNavigation } from '../shared/TabsNavigation';

export type Task = {
  name: string;
  type: string;
  unassigned: number;
  inProgress: number;
};

type Props = {};

export const Queue: React.FC<Props> = () => {
  return (
    <div>
      <TabsNavigation
        items={[
          {
            link: '/tasks/annotation/1',
            label: 'Annotation task',
            section: 'annotation',
          },
          {
            link: '/tasks/statistics/1',
            label: 'Statistics',
            section: 'statistics',
          },
          {
            link: '/tasks/queue/1',
            label: 'Queue',
            section: 'queue',
          },
        ]}
        activeSection="queue"
      />

      <div
        className="card m-4"
        data-list='{"valueNames": ["item-name", "item-title", "item-email", "item-phone", "item-score", "item-company"], "page": 10, "pagination": {"paginationClass": "list-pagination"}}'
        id="contactsList"
      >
        <div className="card-header">
          <div className="row align-items-center">
            <div className="col">
              {/* <!-- Form --> */}
              <form>
                <div className="input-group input-group-flush input-group-merge input-group-reverse">
                  <input className="form-control list-search" type="search" placeholder="Search" />
                  <span className="input-group-text">
                    <i className="fe fe-search"></i>
                  </span>
                </div>
              </form>
            </div>
          </div>
          {/*  <!-- / .row --> */}
        </div>
        <div className="table-responsive">
          <table className="table table-sm table-hover table-nowrap card-table">
            <thead>
              <tr>
                <th>
                  <a className="list-sort text-muted" data-sort="item-name" href="!#">
                    NAME
                  </a>
                </th>
                <th>
                  <a className="list-sort text-muted" data-sort="item-name" href="!#">
                    DATE ADDED
                  </a>
                </th>
                <th>
                  <a className="list-sort text-muted" data-sort="item-name" href="!#">
                    STATUS
                  </a>
                </th>
                <th>
                  <a className="list-sort text-muted" data-sort="item-company" href="!#">
                    ACTIONS
                  </a>
                </th>
              </tr>
            </thead>
            <tbody className="list fs-base">
              <tr>
                <td>Document 1</td>
                <td>2021-Jan-01</td>
                <td>Unassigned</td>
                <td>
                  <div className="btn-group float-right" role="group" aria-label="">
                    <a href="#!" className="btn btn-white">
                      Details
                    </a>
                    <a href="#!" className="btn btn-danger">
                      Delete
                    </a>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
