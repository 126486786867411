import React from 'react';

type Props = {
  nextHandler: (section: string) => void;
};

export const SkillsSection: React.FC<Props> = ({ nextHandler }) => {
  return (
    <form>
      <div className="form-group">
        <label htmlFor="exampleInputEmail1" className="form-label">
          Mandatory skills
        </label>
        <textarea className="form-control" id="mandatorySkills" autoComplete="off" />
      </div>
      <div className="form-group">
        <label htmlFor="type" className="form-label">
          Desired skills
        </label>
        <textarea className="form-control" id="desiredSkills" autoComplete="off" />
      </div>

      <a className="btn btn-primary" href="#!" onClick={() => nextHandler('tribes')}>
        Next
      </a>
    </form>
  );
};
