import React from 'react';
import { TabsNavigation } from '../shared/TabsNavigation';
import { AverageProcessingTime } from './AverageProcessingTime';
import { NumberOfProcessedDocuments } from './NumberOfProcessedDocuments';
import { NumberOfUnassignedDocuments } from './NumberOfUnassignedDocuments';
import { Total } from './Total';

export type Task = {
  name: string;
  type: string;
  unassigned: number;
  inProgress: number;
};

type Props = {};

export const Statistics: React.FC<Props> = () => {
  return (
    <div>
      <TabsNavigation
        items={[
          {
            link: '/tasks/annotation/1',
            label: 'Annotation task',
            section: 'annotation',
          },
          {
            link: '/tasks/statistics/1',
            label: 'Statistics',
            section: 'statistics',
          },
          {
            link: '/tasks/queue/1',
            label: 'Queue',
            section: 'queue',
          },
        ]}
        activeSection="statistics"
      />

      <div className="m-4">
        <div className="row">
          <div className="col-6">
            <AverageProcessingTime />
          </div>
          <div className="col-6">
            <NumberOfProcessedDocuments />
          </div>
        </div>
        <div className="row mt-4 pb-4">
          <div className="col-6">
            <NumberOfUnassignedDocuments />
          </div>
          <div className="col-6">
            <Total />
          </div>
        </div>
      </div>
    </div>
  );
};
