import React from 'react';
import { Link } from 'react-router-dom';

type Props = {
  section: string;
};
export const Navbar: React.FC<Props> = ({ section }) => {
  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <div className="container-fluid">
        <a className="navbar-brand mx-4" href="/">
          <img src="/img/tribetip.png" alt="" style={{ maxHeight: '50px' }} className="d-inline-block align-text-top" />
        </a>
        <div className="me-auto">
          <ul className="navbar-nav">
            <li className="nav-item">
              <Link className={`nav-link ${section === 'tribes' && 'active'}`} aria-current="page" to="/">
                Tribes
              </Link>
            </li>
            <li className="nav-item">
              <Link className={`nav-link ${section === 'tasks' && 'active'}`} aria-current="page" to="/tasks">
                Tasks
              </Link>
            </li>
          </ul>
        </div>
        <a className="me-4" href="!#">
          <img src="/img/settings.svg" alt="settings" height="25" />
        </a>
      </div>
    </nav>
  );
};
