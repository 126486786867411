import React from 'react';

type Props = {
  link: string;
};

export const AddButton: React.FC<Props> = ({ link }) => {
  return (
    <a
      className="btn btn-rounded-circle btn-success btn-bottom-right-fixed m-3"
      href={link}
      style={{
        position: 'absolute',
        right: 0,
        bottom: 0,
      }}
    >
      <span className="fe fe-plus"></span>
    </a>
  );
};
