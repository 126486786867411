import React, { useState } from 'react';

type Props = {
  nextHandler: (section: string) => void;
};

export const MotivationSection: React.FC<Props> = ({ nextHandler }) => {
  const [annotationTimeLimit, setAnnotationTimeLimit] = useState<string>('none');
  return (
    <form>
      <div className="form-group">
        <label htmlFor="linkToVerification" className="form-label d-flex">
          Annotation time limit
        </label>

        <div className="btn-group" data-toggle="buttons">
          <label className={`btn btn-outline-primary ${annotationTimeLimit === 'none' && 'active'}`}>
            <input
              type="radio"
              className="btn-check"
              name="options"
              id="option1"
              autoComplete="off"
              checked={annotationTimeLimit === 'none'}
              onClick={() => setAnnotationTimeLimit('none')}
            />
            None
          </label>
          <label className={`btn btn-outline-primary ${annotationTimeLimit === 'fixed' && 'active'}`}>
            <input
              type="radio"
              className="btn-check"
              name="options"
              id="option1"
              autoComplete="off"
              checked={annotationTimeLimit === 'fixed'}
              onClick={() => setAnnotationTimeLimit('fixed')}
            />
            Fixed
          </label>
          <label className={`btn btn-outline-primary ${annotationTimeLimit === 'gamified' && 'active'}`}>
            <input
              type="radio"
              className="btn-check"
              name="options"
              id="option1"
              autoComplete="off"
              checked={annotationTimeLimit === 'gamified'}
              onClick={() => setAnnotationTimeLimit('gamified')}
            />
            Gamified
          </label>
        </div>
      </div>

      {annotationTimeLimit === 'fixed' && (
        <div className="form-group">
          <label htmlFor="exampleInputEmail1" className="form-label">
            Annotation limit
          </label>

          <div className="row align-items-center">
            <div className="col-auto">
              <input type="text" className="form-control" id="payment" autoComplete="off" />
            </div>
            <div className="col-auto ps-0">
              <span id="passwordHelpInline">seconds</span>
            </div>
          </div>
        </div>
      )}

      <a className="btn btn-primary" href="#!" onClick={() => nextHandler('innolytiq')}>
        Next
      </a>
    </form>
  );
};
