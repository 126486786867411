import React from 'react';
import { TabsNavigation } from '../shared/TabsNavigation';

export type Task = {
  name: string;
  type: string;
  unassigned: number;
  inProgress: number;
};

type Props = {};

export const AnnotationTask: React.FC<Props> = () => {
  return (
    <div>
      <TabsNavigation
        items={[
          {
            link: '/tasks/annotation/1',
            label: 'Annotation task',
            section: 'annotation',
          },
          {
            link: '/tasks/statistics/1',
            label: 'Statistics',
            section: 'statistics',
          },
          {
            link: '/tasks/queue/1',
            label: 'Queue',
            section: 'queue',
          },
        ]}
        activeSection="annotation"
      />

      <div className="m-4">Annotation task</div>
    </div>
  );
};
