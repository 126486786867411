import React from 'react';
import { Link } from 'react-router-dom';

export type Task = {
  name: string;
  type: string;
  unassigned: number;
  inProgress: number;
};

type Props = {
  task: Task;
};

export const TaskRow: React.FC<Props> = ({ task }) => {
  return (
    <tr>
      <td>
        <Link className="item-name text-reset" to={`/tasks/annotation/${task.name}`}>
          {task.name}
        </Link>
      </td>
      <td>{task.type}</td>
      <td>{task.unassigned}</td>
      <td>{task.inProgress}</td>
      <td>
        <a className="btn btn-white" href="!#">
          Edit
        </a>
      </td>
    </tr>
  );
};
