import React from 'react';

type Props = {
  nextHandler: (section: string) => void;
};

export const NameSection: React.FC<Props> = ({ nextHandler }) => {
  return (
    <form>
      <div className="form-group">
        <label htmlFor="exampleInputEmail1" className="form-label">
          Name
        </label>
        <input type="text" className="form-control" id="name" placeholder="Enter name" autoComplete="off" />
      </div>
      <div className="form-group">
        <label htmlFor="type" className="form-label">
          Type
        </label>
        <select className="form-control">
          <option></option>
          <option>Annotation</option>
          <option>Validation</option>
        </select>
      </div>

      <a className="btn btn-primary" href="#!" onClick={() => nextHandler('skills')}>
        Next
      </a>
    </form>
  );
};
