import { ChartData } from 'chart.js';
import React from 'react';
import { Line } from 'react-chartjs-2';

// type Dataset = {
//   label: string[];
//   data: number[];
//   fill: boolean;
//   backgroundColor: string;
//   borderColor: string;
// };

// type ChartData = {
//   labels: string[];
//   datasets: Dataset[];
// };

type Props = {
  data: ChartData<'line'>;
};

export const Chart: React.FC<Props> = ({ data }) => {
  const options = {
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          stepSize: 1,
          beginAtZero: true,
          precision: 0,
        },
      },
    },
  };

  return <Line data={data} options={options} style={{ height: '150px' }} />;
};
