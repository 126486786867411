import React from 'react';
import { AddButton } from '../../shared/AddButton';
import { TabsNavigation } from '../../shared/TabsNavigation';

export const Tribes: React.FC = () => {
  return (
    <>
      <TabsNavigation
        items={[
          {
            link: '/',
            label: 'People',
            section: 'people',
          },
          {
            link: '/tribes',
            label: 'Tribes',
            section: 'tribes',
          },
        ]}
        activeSection="tribes"
      />

      <div
        className="card m-4"
        data-list='{"valueNames": ["item-name", "item-title", "item-email", "item-phone", "item-score", "item-company"], "page": 10, "pagination": {"paginationClass": "list-pagination"}}'
        id="contactsList"
      >
        <div className="card-header">
          <div className="row align-items-center">
            <div className="col">
              {/* <!-- Form --> */}
              <form>
                <div className="input-group input-group-flush input-group-merge input-group-reverse">
                  <input className="form-control list-search" type="search" placeholder="Search" />
                  <span className="input-group-text">
                    <i className="fe fe-search"></i>
                  </span>
                </div>
              </form>
            </div>
          </div>
          {/*  <!-- / .row --> */}
        </div>
        <div className="table-responsive">
          <table className="table table-sm table-hover table-nowrap card-table">
            <thead>
              <tr>
                <th></th>
                <th>
                  <a className="list-sort text-muted" data-sort="item-name" href="!#">
                    Tribe
                  </a>
                </th>
                <th>
                  <a className="list-sort text-muted" data-sort="item-company" href="!#">
                    ACTIONS
                  </a>
                </th>
              </tr>
            </thead>
            <tbody className="list fs-base">
              <tr>
                <td>
                  {/* <!-- Avatar --> */}
                  <div className="avatar avatar-xs align-middle me-2">
                    <img className="avatar-img rounded-circle" src="img/avatars/users.svg" alt="..." />
                  </div>
                </td>
                <td>Tribe annotators</td>
                <td>
                  <a className="btn btn-white" href="!#">
                    Edit
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  {/* <!-- Avatar --> */}
                  <div className="avatar avatar-xs align-middle me-2">
                    <img className="avatar-img rounded-circle" src="img/avatars/users.svg" alt="..." />
                  </div>
                </td>
                <td>Chinese spreaders</td>
                <td>
                  <a className="btn btn-white" href="!#">
                    Edit
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div className="d-flex justify-content-center">
        <nav aria-label="...">
          <ul className="pagination">
            <li className="page-item disabled">
              <span className="page-link">Previous</span>
            </li>
            <li className="page-item disabled">
              <a className="page-link" href="!#">
                Next
              </a>
            </li>
          </ul>
        </nav>
      </div>

      <AddButton link="" />
    </>
  );
};
