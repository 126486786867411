import React, { useState } from 'react';

type Props = {
  nextHandler: (section: string) => void;
};

export const PaymentsSection: React.FC<Props> = ({ nextHandler }) => {
  const [linkToVerification, setLinkToVerification] = useState<string>('yes');
  return (
    <form>
      <div className="form-group">
        <label htmlFor="exampleInputEmail1" className="form-label">
          Payment
        </label>

        <div className="row align-items-center">
          <div className="col-auto">
            <input type="text" className="form-control" id="payment" autoComplete="off" />
          </div>
          <div className="col-auto ps-0">
            <span id="passwordHelpInline">token</span>
          </div>
        </div>
      </div>

      <div className="form-group">
        <label htmlFor="exampleInputEmail1" className="form-label">
          Non-acceeptance deduction
        </label>

        <div className="row align-items-center">
          <div className="col-auto">
            <input type="text" className="form-control" id="payment" autoComplete="off" />
          </div>
          <div className="col-auto ps-0">
            <span id="passwordHelpInline">percent</span>
          </div>
        </div>
      </div>

      <div className="form-group">
        <label htmlFor="linkToVerification" className="form-label d-flex">
          Link to verification
        </label>

        <div className="btn-group btn-group-toggle" data-toggle="buttons">
          <label className={`btn btn-outline-primary ${linkToVerification === 'yes' && 'active'}`}>
            <input
              type="radio"
              className="btn-check"
              name="options"
              id="option1"
              autoComplete="off"
              checked={linkToVerification === 'none'}
              onClick={() => setLinkToVerification('yes')}
            />
            Yes
          </label>
          <label className={`btn btn-outline-primary ${linkToVerification === 'no' && 'active'}`}>
            <input
              type="radio"
              className="btn-check"
              name="options"
              id="option3"
              autoComplete="off"
              checked={linkToVerification === 'none'}
              onClick={() => setLinkToVerification('no')}
            />
            No
          </label>
        </div>
      </div>

      <a className="btn btn-primary" href="#!" onClick={() => nextHandler('motivation')}>
        Next
      </a>
    </form>
  );
};
