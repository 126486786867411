import React, { useState } from 'react';
import { InnolytiqSection } from './sections/InnolytiqSection';
import { MotivationSection } from './sections/MotivationSection';
import { NameSection } from './sections/NameSections';
import { PaymentsSection } from './sections/PaymentsSection';
import { Sections } from './sections/Sections';
import { SkillsSection } from './sections/SkillsSection';
import { TribesSection } from './sections/TribesSection';

type Props = {
  section: string;
};

export const NewTask: React.FC<Props> = (props) => {
  const [section, setSection] = useState<string>(props.section);
  return (
    <div className="row">
      <div className="col-2 ms-4 mt-4">
        <Sections section={section} sectionHandler={setSection} />
      </div>
      <div className="col-4 mt-4 me-4">
        {section === 'name' ? (
          <NameSection nextHandler={setSection} />
        ) : section === 'skills' ? (
          <SkillsSection nextHandler={setSection} />
        ) : section === 'tribes' ? (
          <TribesSection nextHandler={setSection} />
        ) : section === 'payments' ? (
          <PaymentsSection nextHandler={setSection} />
        ) : section === 'motivation' ? (
          <MotivationSection nextHandler={setSection} />
        ) : section === 'innolytiq' ? (
          <InnolytiqSection nextHandler={setSection} />
        ) : null}
      </div>
    </div>
  );
};
